import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./routes/controler";
import { ProSidebarProvider } from "react-pro-sidebar";

import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ProSidebarProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ProSidebarProvider>
);
