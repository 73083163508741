import React from "react";
import DataTable from "../../../components/datatable";

export default function EmailHistoryTAB() {
  return (
    <>
      {" "}
      <DataTable></DataTable>
    </>
  );
}
